@font-face {
  font-family: 'SF-Pro-Rounded-Thin';
  src: url('../src/assets/Fonts/SF-Pro-Rounded-Thin.otf') format('opentype');
}

.App {
  background: linear-gradient(to right, #009BBC, #023A4E);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
 
}


.landing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: linear-gradient(to right, #009BBC, #023A4E);;
  color: white;
  text-align: center;
  padding: 20px;
}

.logo {
  width: 150px;
  height: auto;
}


/* #################### HEADER ##################### */
.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  height: 20px;
  background-color: transparent;
}

.App-logo {
  height: 140px;
  width: auto;
  object-fit: cover;
  object-position: center;
}

.App-labels {
  display: flex;
  justify-content: center;
  flex: 1;
}

.App-label {
  margin: 0 15px;
  color: white;
  font-size: 18px;
}

/* #################### Coming Soon ##################### */

@font-face {
  font-family: 'Lexend Semi-bold';
  src: url(../src/assets/Fonts/Lexend-VariableFont_wght.ttf) format('truetype');
}


.coming-soon {
  margin-top: 90px;
  margin-bottom: 90px;
  font-size: 78px;
  font-family: 'Lexend Semi-bold', sans-serif;
}

.email-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  max-width: 500px;
}

.email-input {

  width: 80%;
  max-width: 500px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 25px;
  font-size: 16px;
  box-sizing: border-box;
}

.submit-button {
  width: 80%;
  max-width: 500px;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  background-color: #009BBC;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

.submit-button:hover {
  background-color: #023A4E;
}

.message {
  margin-top: 20px;
  color: white;
  font-family: 'Lexend Semi-bold', sans-serif;
}

/* Media queries for smaller screens */
@media screen and (max-width: 768px) {
  .coming-soon {
    font-size: 50px;
  }

  .logo {
    width: 80px;
  }

  .email-input {
    width: 90%; /* Adjust width for smaller screens */
  }
}

@media screen and (max-width: 480px) {
  .coming-soon {
    font-size: 36px;
  }

  .logo {
    width: 60px;
  }

  .email-input {
    width: 100%; /* Adjust width for smaller screens */
  }
}